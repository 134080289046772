import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { map, take } from 'rxjs/operators';

import { AllTradeGraph, DashboardTrade, InfoTrade, PageTrade, Trade, TradeStatus, UserTradeGem } from '../../interfaces/trade.interface';

import { MatchEnum, SearchField } from '../../interfaces/services.interface';
import { RequestService } from '../../core/services/request/request.service';

@Injectable({
    providedIn: 'root'
})
export class TradesService {

    constructor(private requestService: RequestService) { }

    getInfoTrade(code: string): Observable<InfoTrade> {
        return this.requestService.get(`trade/${ code }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    getTradesV1(limit: number = 10, skip: number = 0, search: string = undefined, filterInit: string = undefined): Observable<PageTrade<DashboardTrade> > {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'usuario', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('trade/v1', limit, skip, searchFields, filterInit);
    }

    getTradesV1ByStatus(limit: number = 10, skip: number = 0, search: string = undefined, filterInit: string = undefined): Observable<PageTrade<TradeStatus> > {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'promotion', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('trade/v1', limit, skip, searchFields, filterInit);
    }

    getTradesV2(limit: number = 10, skip: number = 0, search: string = undefined, filterInit: string = undefined): Observable<PageTrade<Trade>> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'promotionname', value: search, match: MatchEnum.CONTAINS });
            searchFields.push({ field: 'ally', value: search, match: MatchEnum.CONTAINS });
            searchFields.push({ field: 'status', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('trade/v2', limit, skip, searchFields, filterInit);
    }

    getTradesTokenGem(type: string, limit: number = 10, skip: number = 0, search: string = undefined, filterInit: string = undefined): Observable<PageTrade<UserTradeGem> > {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'user', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage(`trade/detail/${ type }`, limit, skip, searchFields, filterInit);
    }

    changeTrade(trade: string): Observable<any> {        
        // update
        return this.requestService.patch(`trade`, { trade }).pipe(
            map( (data: AjaxResponse) => data.response )
        );        
    }

    getAllTradeGraph(startAt: String, endAt: String, status: Array<String>): Observable<AllTradeGraph> {
        let params = '';

        if(startAt !== undefined && startAt !== '') {
            params += params === '' ? '?' : '&';
            params += `startAt=${ startAt }`;
        }

        if(endAt !== undefined && endAt !== '') {
            params += params === '' ? '?' : '&';
            params += `endAt=${ endAt }`;
        }

        if(status && status.length > 0) {
            status.forEach(statu => {
                params += params === '' ? '?' : '&';
                params += `status=${ statu }`;
            });
        }
        return this.requestService.get(`trade/detail/graphic${ params }`).pipe(
            map( data => data.response)
        );
    }
}
