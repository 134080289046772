export enum GraphStatusTrade {
    CANJEADO = 'CANJEADO',
    PENDIENTE = 'PENDIENTE',
    ERROR = 'ERROR'
}

export enum GraphStatusClaim {
    RESUELTO = 'RESUELTO',
    EN_PROCESO = 'EN_PROCESO',
    SIN_RESOLVER = 'SIN_RESOLVER'
}