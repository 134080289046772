import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import { RequestService } from '../../core/services/request/request.service';

import { Catalog, CatalogMin } from '../../interfaces/services.interface';

@Injectable({
    providedIn: 'root'
})
export class DepartamentsService {

    constructor(private requestService: RequestService) { }

    getDepartaments(): Observable<Array<CatalogMin>> {
        return this.requestService.get('department', null).pipe(
            map( (data: AjaxResponse) => data.response.results ),
            map( (departaments: Array<Catalog>) => {
                return departaments.map( ({id, name}): CatalogMin => {
                    return { id, name };
                } );
            })
        );
    }
}
