import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration, ConfigurationGemMin, ConfigurationGems, ConfigurationMin } from '../../interfaces/configurations.interface';

import { RequestService } from '../../core/services/request/request.service';
import { AjaxResponse } from 'rxjs/ajax';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsService {

    constructor(private requestService: RequestService) { }

    getAllConfigurations(): Observable<Array<ConfigurationMin>> {
        return this.requestService.get('configuration', null).pipe(
            map( (data: AjaxResponse) => data.response.results ),
            map( (categories: Array<Configuration>) => {
                return categories.map( ({id, title, description, type}): ConfigurationMin => {
                    return { id, title, description, type };
                } );
            })
        );
    }

    getConfiguration(id: string): Observable<ConfigurationMin> {
        return this.requestService.get(`configuration/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    getConfigurationGems(): Observable<Array<ConfigurationGemMin>> {
        return this.requestService.get(`configuration/gems`).pipe( 
            map( (data: AjaxResponse) => data.response.results ),
            map( (configs: Array<ConfigurationGems>) => {
                return configs.map( ({id, title, description, type, value}): ConfigurationGemMin => {
                    return { id, title, description, type, value };
                } );
            })
        );
    }

    saveConfiguration(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('configuration', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`configuration/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }
}
