export enum CategoryType {
    ALLY = 'ALLY',
    PROMOTION = 'PROMOTION',
    PROMOTION_TYPE = 'PROMOTION_TYPE',
    PLAY = 'PLAY',
    BOOST = 'BOOST',
    CONTACT = 'CONTACT',
    RIDDLE = 'RIDDLE',
    TRIVIA = 'TRIVIA',
    INQUIRY = 'INQUIRY'
}