import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

import { ContentsBanner, ContentSection, ContentSectionMin, ContentsPoll, ContentsRiddle, ContentsTrivia, PageContentsBanner, PageContentsPoll, PageContentsRiddle, PageContentsTrivia } from '../../interfaces/contents.interface';
import { Catalog, CatalogMin, MatchEnum, SearchField } from '../../interfaces/services.interface';
import { UploadImage } from '../../interfaces/upload-image';

import { RequestService } from '../../core/services/request/request.service';


@Injectable({
    providedIn: 'root'
})
export class ContentsService {

    constructor(private requestService: RequestService) { }

    getContentsBanner(limit: number = 10, skip: number = 0, search: string = undefined): Observable<PageContentsBanner> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'name', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('content', limit, skip, searchFields);
    }

    getContentsTrivia(limit: number = 10, skip: number = 0, search: string = undefined): Observable<PageContentsTrivia> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'question', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('trivia', limit, skip, searchFields);
    }

    getContentsRiddle(limit: number = 10, skip: number = 0, search: string = undefined): Observable<PageContentsRiddle> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'question', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('riddle', limit, skip, searchFields);
    }

    getContentsPoll(limit: number = 10, skip: number = 0, search: string = undefined): Observable<PageContentsPoll> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'question', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('inquiry', limit, skip, searchFields);
    }

    patchTriviaVisible(id: string, data: any): Observable<any> {
        return this.requestService.patch(`trivia/${ id }`, data).pipe(
            map( (data: AjaxResponse) => data.response )
        );
    }

    patchRiddleVisible(id: string, data: any): Observable<any> {
        return this.requestService.patch(`riddle/${ id }`, data).pipe(
            map( (data: AjaxResponse) => data.response )
        );
    }

    patchPollVisible(id: string, data: any): Observable<any> {
        return this.requestService.patch(`inquiry/${ id }`, data).pipe(
            map( (data: AjaxResponse) => data.response )
        );
    }

    getContentsSectionMin(type: string): Observable<Array<ContentSectionMin>> {
        return this.requestService.get(`contentsection?type=${ type }`, null).pipe(
            map( (data: AjaxResponse) => data.response.results ),
            map( (types: Array<ContentSection>) => {
                return types.map( ({id, name, code, type}): ContentSectionMin => {
                    return { id, name, code, type };
                } );
            })
        );
    }

    getBanner(id: string): Observable<ContentsBanner> {
        return this.requestService.get(`content/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    getTrivia(id: string): Observable<ContentsTrivia> {
        return this.requestService.get(`trivia/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    getRiddle(id: string): Observable<ContentsRiddle> {
        return this.requestService.get(`riddle/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    getPoll(id: string): Observable<ContentsPoll> {
        return this.requestService.get(`inquiry/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    async uploadImage(file: File): Promise<Observable<UploadImage>> {
        return this.requestService.uploadFile('upload', 'image', file).toPromise();
    }

    saveBanner(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('content', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`content/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }

    saveTrivia(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('trivia', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`trivia/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }

    saveRiddle(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('riddle', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`riddle/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }

    savePoll(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('inquiry', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`inquiry/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }
}
