import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Category, CategoryType, PageCategory } from '../../interfaces/categories.interface';
import { MatchEnum, SearchField } from '../../interfaces/services.interface';

import { RequestService } from '../../core/services/request/request.service';
import { map, take } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    constructor(private requestService: RequestService) { }

    getCategoriesType(section: String): Observable<Array<CategoryType>> {
        return this.requestService.get(`category?section=${ section }`, null).pipe(
            map( (data: AjaxResponse) => data.response.results),
            map( (type: Array<Category>) => {
                return type.map( ({id, name, photo}): CategoryType => {
                    return { id, name, photo };
                })
            })
        );
    }

    getCategoriesPage(limit: number = 10, skip: number = 0, search: string = undefined): Observable<PageCategory> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'name', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('category', limit, skip, searchFields);
    }

    getCategory(id: string): Observable<Category> {
        return this.requestService.get(`category/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    saveCategory(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('category', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`category/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }

}
