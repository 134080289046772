import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { map, take } from 'rxjs/operators';

import { RequestService } from '../../core/services/request/request.service';
import { AllAllyGraph, Ally, AllyType, PageAllies, PageBrands } from '../../interfaces/allies.interface';
import { Catalog, CatalogMin, MatchEnum, SearchField } from '../../interfaces/services.interface';
import { UploadImage } from '../../interfaces/upload-image';

@Injectable({
    providedIn: 'root'
})
export class AlliesService {

    constructor(private requestService: RequestService) { }

    getAllies(limit: number = 10, skip: number = 0, search: string = undefined, filterInit: string = undefined): Observable<PageAllies> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'name', value: search, match: MatchEnum.CONTAINS });
            searchFields.push({ field: 'address', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('ally', limit, skip, searchFields, filterInit);
    }

    getBrands(allyId: string, limit: number = 10, skip: number = 0, search: string = undefined): Observable<PageBrands> {
        const searchFields: Array<SearchField> = [];
        if(search !== undefined && search !== '' ) {
            searchFields.push({ field: 'name', value: search, match: MatchEnum.CONTAINS });
        }
        return this.requestService.getByPage('allybrand', limit, skip, searchFields, `field=parent.id&value=${ allyId }&match=equals`);
    }

    getAlliesMin(): Observable<Array<AllyType>> {
        return this.requestService.get('ally', null).pipe(
            map( (data: AjaxResponse) => data.response.results ),
            map( (allies: Array<Ally>) => {
                return allies.map( ({id, name, affiliationcode}): AllyType => {
                    return { id, name, affiliationcode };
                } );
            })
        );
    }

    getAlly(id: string): Observable<Ally> {
        return this.requestService.get(`ally/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }

    saveAlly(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('ally', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`ally/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }

    deletedAlly(id: string): Observable<any> {
        return this.requestService.deleted(`ally/${ id }`).pipe(
            map( (data: AjaxResponse) => data.response )
        );
    }

    saveAllyBrand(id: string, data: any): Observable<any> {
        if(id === '') {
            // new
            return this.requestService.post('allybrand', data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        } else {
            // update
            return this.requestService.patch(`allybrand/${ id }`, data).pipe(
                map( (data: AjaxResponse) => data.response )
            );
        }
    }

    async uploadImage(file: File): Promise<Observable<UploadImage>> {
        return this.requestService.uploadFile('upload', 'image', file).toPromise();
    }

    getAllAllyGraph(startAt: String, endAt: String, allies: Array<String>): Observable<AllAllyGraph> {
        let params = '';

        if(startAt !== undefined && startAt !== '') {
            params += params === '' ? '?' : '&';
            params += `startAt=${ startAt }`;
        }

        if(endAt !== undefined && endAt !== '') {
            params += params === '' ? '?' : '&';
            params += `endAt=${ endAt }`;
        }

        if(allies && allies.length > 0) {
            allies.forEach(ally => {
                params += params === '' ? '?' : '&';
                params += `ally=${ ally }`;
            });
        }
        return this.requestService.get(`ally/report/graphic${ params }`).pipe(
            map( data => data.response)
        );
    }

    getAllyGraph(id: string, startAt: String, endAt: String, allies: Array<String>): Observable<AllAllyGraph> {
        let params = '';

        if(startAt !== undefined && startAt !== '') {
            params += params === '' ? '?' : '&';
            params += `startAt=${ startAt }`;
        }

        if(endAt !== undefined && endAt !== '') {
            params += params === '' ? '?' : '&';
            params += `endAt=${ endAt }`;
        }

        if(allies && allies.length > 0) {
            allies.forEach(ally => {
                params += params === '' ? '?' : '&';
                params += `ally=${ ally }`;
            });
        }
        return this.requestService.get(`ally/${id}/report/graphic${ params }`).pipe(
            map( data => data.response)
        );
    }

    importFile(id: string, url: string, data: any): Observable<any> {            
        return this.requestService.post(`import/ally/${ id }/${ url }`, data).pipe(
            map( (data: AjaxResponse) => data.response )
        );        
    }
}
